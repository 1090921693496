import { FaTable } from "react-icons/fa";
import ListingGridView from "../Listings/ListingGridView";
import ListingListView from "../Listings/ListingListView";
import { Button, Row } from "react-bootstrap";
import { doListingShortlist, fetchDynamicListings } from "../../services/meta";
import { useEffect, useState } from "react";
import { decodeJWT } from "../../helpers/authHelper";
import { toast } from "react-toastify";
import styled from "styled-components";
import { FaTableCells, FaTableCellsLarge } from "react-icons/fa6";
import ListingMobileGridView from "../Listings/ListingMobileGridView";
import { MdOutlineManageSearch } from "react-icons/md";
import { useNavigate } from "react-router-dom";
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  // border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  margin: 16px 0;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #fff;
  border-bottom: 1px solid #e9ecef;
`;

const TotalRecords = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #495057;
`;

const ViewToggle = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  .icon {
    cursor: pointer;
    font-size: 24px;
    margin-left: 16px;
  }
`;
const DynamicListings = ({ OnOpenLoginModal, isMobile }) => {
  const decodedJWT = decodeJWT();
  const navigate = useNavigate();
  const [listings, setListings] = useState([]);
  const [page, setPage] = useState(1);
  const [totalListings, setTotalListings] = useState(0);
  const [loading, setLoading] = useState(false);
  const [defaultListingView, setDefaultListingView] = useState("grid");
  const _doListingShortlist = async (listingId, flag) => {
    if (decodedJWT) {
      await doListingShortlist({
        listing_id: listingId,
        flag,
      })
        .then((response) => {
          toast.success(response.data.message);
        })
        .catch((err) => {
          // toast.error(err.response.data.message);
        });
      return flag ? true : false;
    } else {
      OnOpenLoginModal();
      return false;
    }
  };
  const loadMoreListings = async (page) => {
    try {
      const response = await fetchDynamicListings({ page });
      const { data } = response;

      setListings((prevListings) => {
        const existingIds = new Set(prevListings.map((item) => item.id));
        const filteredListings = data.data.listings.filter(
          (listing) => !existingIds.has(listing.id)
        );

        return [...prevListings, ...filteredListings];
      });

      setPage((prevPage) => prevPage + 1);
      setTotalListings(data.data.page_context.total);
    } catch (err) {
      console.log(err.response?.data || err.message);
    }
  };
  useEffect(() => {
    loadMoreListings(1);
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        loadMoreListings(page);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [page]);
  return (
    <>
      <CardContainer>
        <Header>
          <TotalRecords>Total Listings: {totalListings}</TotalRecords>
          {!isMobile ? (
            <ViewToggle active={defaultListingView === "list"}>
              <Button
                className="btn btn-xs btn-secondary"
                onClick={() => navigate("/search?show_adv=1")}
              >
                <MdOutlineManageSearch
                  size={24}
                  className={isMobile ? "" : "me-2"}
                />
                {!isMobile ? "Advanced Search" : null}
              </Button>
              <FaTableCells
                className={`icon ${
                  defaultListingView !== "list" ? "text-success" : ""
                }`}
                onClick={() => setDefaultListingView("grid")}
              />
              {!isMobile ? (
                <FaTableCellsLarge
                  className={`icon ${
                    defaultListingView === "list" ? "text-success" : ""
                  }`}
                  onClick={() => setDefaultListingView("list")}
                />
              ) : null}
            </ViewToggle>
          ) : (
            <ViewToggle active={defaultListingView === "list"}>
              <Button
                className="btn btn-xs btn-secondary"
                onClick={() => navigate("/search")}
              >
                <MdOutlineManageSearch
                  size={24}
                  className={isMobile ? "" : "me-2"}
                />
                {!isMobile ? "Advanced Search" : null}
              </Button>
            </ViewToggle>
          )}
        </Header>
      </CardContainer>
      {listings.length ? (
        isMobile ? (
          <Row xs={2} sm={2} md={2} className="mx-0">
            {listings.map((e) => {
              return (
                <ListingMobileGridView
                  listing={e}
                  OnDoListingShortlist={_doListingShortlist}
                />
              );
            })}
          </Row>
        ) : defaultListingView === "list" ? (
          <Row xs={1} sm={2} md={2} className="mx-0">
            {listings.map((e) => {
              return (
                <ListingListView
                  listing={e}
                  OnDoListingShortlist={_doListingShortlist}
                />
              );
            })}
          </Row>
        ) : (
          <Row xs={1} sm={2} md={4} className="mx-0">
            {listings.map((e) => {
              return (
                <ListingGridView
                  listing={e}
                  OnDoListingShortlist={_doListingShortlist}
                />
              );
            })}
          </Row>
        )
      ) : (
        <>
          <div className="text-center pt-2 pt-md-4 pt-lg-5 pb-2 pb-md-0">
            <i className="fi-heart display-6 text-muted mb-4"></i>
            <h2 className="h5 mb-2">
              Listing isn't available as per your search!
            </h2>
          </div>
        </>
      )}
      {loading && <p>Loading...</p>}
    </>
  );
};

export default DynamicListings;
